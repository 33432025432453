import React, {Suspense, lazy} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Col, Container, Row} from 'react-bootstrap';
import PrivateRoute from "./PrivateRoute";
import Topbar from "./components/layout/Topbar";
import Sidebar from "./components/layout/Sidebar";
import './App.css';
import {observer} from 'mobx-react-lite';
import {authStore} from './stores/authStore';
import 'bootstrap/dist/css/bootstrap.min.css';
import Loader from "./components/common/Loader";
import GlobalConfigPage from "./pages/GlobalConfigPage";
import AddNewRolePage from "./pages/AddNewRolePage";
import BreakManagementPage from "./pages/BreakManagementPage";

const Users = lazy(() => import('./pages/UsersPage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const ExpensesPage = lazy(() => import('./pages/ExpensesPage'));
const TimeTrackingPage = lazy(() => import('./pages/TimeTrackingPage'));
const HistoricalPage = lazy(() => import('./pages/HistoricalPage'));
const TimePage = lazy(() => import('./pages/TimePage'));
const LeavesPage = lazy(() => import('./pages/LeavesPage'));
const RemoteWorkPage = lazy(() => import('./pages/RemoteWorkPage'));
const DepartmensPage = lazy(() => import ('./pages/DepartmentsPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

const App = observer(() => {
    const permissions = authStore.permissions;

    return (
        <div className="App">
            <Container fluid>
                <Row className="flex-nowrap">
                    {authStore.isAuthenticated &&
                        <Col
                            xs={6}
                            sm={5}
                            md={4}
                            lg={4}
                            xl={3}
                            xxl={2}
                            className="sidebar-wrapper pb-5">
                            <Sidebar/>
                        </Col>
                    }
                    <Col
                        xs={authStore.isAuthenticated ? 6 : 12}
                        sm={authStore.isAuthenticated ? 7 : 12}
                        md={authStore.isAuthenticated ? 8 : 12}
                        lg={authStore.isAuthenticated ? 8 : 12}
                        xl={authStore.isAuthenticated ? 9 : 12}
                        xxl={authStore.isAuthenticated ? 10 : 12}

                        className={authStore.isAuthenticated ? 'main-content pb-5' : 'wrap-login-page'}>
                        {authStore.isAuthenticated && <Topbar/>}
                        <Suspense fallback={<div><Loader/></div>}>
                            <Routes>
                                <Route path="/login" element={<LoginPage/>}/>
                                <Route path="/forgot-password-page" element={<ForgotPasswordPage/>}/>
                                ForgotPasswordPage
                                {authStore.isAuthenticated ? (
                                    <>
                                        {authStore.selectedDepartmentId && (
                                            <>
                                                {permissions?.sidebarManagement?.sidebarViewExpenses &&
                                                    (
                                                        <Route path="/expences/*"
                                                               element={<PrivateRoute><ExpensesPage/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewAbsences &&
                                                    (
                                                        <>
                                                            <Route path="/absences/*"
                                                                   element={
                                                                       <PrivateRoute><LeavesPage/></PrivateRoute>}/>
                                                        </>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewRemoteWork &&
                                                    (
                                                        <Route path="/remote-work/*"
                                                               element={
                                                                   <PrivateRoute><RemoteWorkPage/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewUsers &&
                                                    (
                                                        <Route path="/users/*"
                                                               element={<PrivateRoute><Users/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewBreakManagement &&
                                                    (
                                                        <Route path="/break-management/*" element={
                                                            <PrivateRoute><BreakManagementPage/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewHistorical &&
                                                    (
                                                        <Route path="/historical/*"
                                                               element={
                                                                   <PrivateRoute><HistoricalPage/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewTime &&
                                                    (
                                                        <Route path="/time/*"
                                                               element={<PrivateRoute><TimePage/></PrivateRoute>}/>
                                                    )
                                                }

                                                {permissions?.sidebarManagement?.sidebarViewGlobalConfig &&
                                                    (
                                                        <>
                                                            <Route path="/global-config/*"
                                                                   element={
                                                                       <PrivateRoute><GlobalConfigPage/></PrivateRoute>}/>
                                                            <Route path="/global-config/new/:roleId?"
                                                                   element={
                                                                       <PrivateRoute><AddNewRolePage/></PrivateRoute>}
                                                            />
                                                        </>
                                                    )
                                                }
                                            </>
                                        )}

                                        {permissions?.sidebarManagement?.sidebarViewDepartments &&
                                            (
                                                <>
                                                    <Route path="/departments"
                                                           element={<PrivateRoute><DepartmensPage/></PrivateRoute>}/>
                                                </>
                                            )
                                        }

                                        {permissions?.sidebarManagement?.sidebarViewTimeTracking &&
                                            (
                                                <Route path="/time-tracking/*"
                                                       element={<PrivateRoute><TimeTrackingPage/></PrivateRoute>}/>
                                            )
                                        }

                                        {permissions?.sidebarManagement?.sidebarViewTimeTracking &&
                                            (
                                                <Route path="/"
                                                       element={<PrivateRoute><TimeTrackingPage/></PrivateRoute>}/>
                                            )
                                        }
                                    </>
                                ) : (
                                    <Route path="*" element={<Navigate to="/login"/>}/>
                                )}
                                {!authStore.isLoading && (<Route path="*" element={<NotFoundPage/>}/>)}
                            </Routes>
                        </Suspense>
                    </Col>
                </Row>
            </Container>
        </div>
    );
});

export default App;
