import React from 'react';
import '../../assets/styles/Modal.css';
import '../../assets/styles/CustomBootstrap.css'
import {Dropdown, Pagination} from 'react-bootstrap';
import arrowLeft from "../../assets/images/icons/arrow-left.png";
import arrowRight from "../../assets/images/icons/arrow-right.png";

const CustomPagination = ({store, handlePageChange, handleItemsPerPageChange}) => {
    if (
        !store ||
        !store.itemsPerPage ||
        !store.currentPage ||
        !store.totalItems ||
        !handlePageChange ||
        !handleItemsPerPageChange
    ) {
        return null;
    }

    return (
        <div className="d-flex justify-content-between align-items-baseline">
            {
                <Dropdown drop="up" className={'select-pagination-per-page'}
                          onSelect={(eventKey) => handleItemsPerPageChange(parseInt(eventKey, 10))}>
                    <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        {store.itemsPerPage} per page
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey="10">10</Dropdown.Item>
                        <Dropdown.Item eventKey="20">20</Dropdown.Item>
                        <Dropdown.Item eventKey="50">50</Dropdown.Item>
                        <Dropdown.Item eventKey="100">100</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            }
            <Pagination>
                {store.currentPage > 1 && (
                    <Pagination.Prev onClick={() => handlePageChange(store.currentPage - 1)}>
                        <img style={{width: '16px', marginRight: '5px'}} src={arrowLeft} alt="Previous"/>
                        Prev
                    </Pagination.Prev>
                )}
                {Array.from({length: Math.ceil(store.totalItems / store.itemsPerPage)}, (_, i) => (
                    <Pagination.Item key={i} active={i + 1 === store.currentPage}
                                     onClick={() => handlePageChange(i + 1)}>
                        {i + 1}
                    </Pagination.Item>
                ))}
                {store.currentPage < Math.ceil(store.totalItems / store.itemsPerPage) && (
                    <Pagination.Next onClick={() => handlePageChange(store.currentPage + 1)}>
                        Next
                        <img style={{width: '16px', marginLeft: '5px'}} src={arrowRight} alt="Next"/>
                    </Pagination.Next>
                )}
            </Pagination>
        </div>
    );
};

export default CustomPagination;
