import axiosConfig from './axiosConfig';
import {camelizeKeys, decamelizeKeys} from 'humps';

const constantsApi = {
    constants: async () => {
        const response = await axiosConfig.get('/get-app-options');
        return camelizeKeys(response.data);
    },
};

export default constantsApi;
