import {createContext, useContext} from 'react';

import roleApi from "../api/roleApi";
import BaseStore from "./baseStore";
import {action, makeObservable, observable, runInAction} from "mobx";
import api from "../api/roleApi";

class GlobalConfigStore extends BaseStore {
    capabilities = [];
    role = {name: ''};
    allUsers = [];
    usersWithRole = []
    userIds = [];

    constructor() {
        super();
        this.api = roleApi;
        makeObservable(this, {
            capabilities: observable,
            role: observable,
            allUsers: observable,
            usersWithRole: observable,
            loadCapabilities: action,
            togglePermission: action,
            changeRoleName: action
        });
    }

    initializeData() {
        this.loadData();
    }

    loadData() {
        super.loadData(this.api);
    }

    async loadCapabilities(roleId = null) {
        try {
            runInAction(() => {
                this.loading = true;
                this.isFirstLoadPage = false;
            });

            const queryParams = {};
            const response = await api.getCapabilities(roleId);

            runInAction(() => {
                this.capabilities = response?.data?.data?.capabilities ?? response.data.capabilities ?? response.capabilities;
                //  this.role = response?.data?.data?.role ?? response.data.role ?? response.role;
                this.role = response.data.role || {name: ''};
                this.allUsers = response?.data?.data?.allUsers ?? response.data.allUsers ?? response.allUsers;
                this.usersWithRole = response?.data?.data?.usersWithRole ?? response.data.usersWithRole ?? response.usersWithRole;
                this.capabilities = this.transformStatus(this.capabilities.sort((a, b) => a.id - b.id));
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                return error?.response?.data?.message ?? error;
            });
            console.error("Loading expenses failed", error);
        } finally {
            this.loading = false;
            this.isFirstLoadPage = false;
        }
    }

    transformStatus(data) {
        return data.map(capability => ({
            ...capability,
            permissions: capability.permissions.map(permission => ({
                ...permission,
                status: parseInt(permission.status, 10) === 1 ? true : false
            }))
        }));
    };

    togglePermission(capabilityId, permissionId) {
        runInAction(() => {
            const capability = this.capabilities.find(c => c.id === capabilityId);
            if (capability) {
                const permission = capability.permissions.find(p => p.id === permissionId);
                if (permission) {
                    if (permission.radioFieldName) {
                        capability.permissions.forEach(item => {
                            if (item.id !== permissionId && item.radioFieldName != null) {
                                item.status = false;
                            }
                        });
                        permission.status = true;
                    } else {
                        permission.status = !permission.status;
                    }
                }
            }
        })
    }

    changeRoleName(name) {
        runInAction(() => {
            const role = this.role;
            role.name = name;
        });
    }

    async addModel(model) {
        try {
            const capabilitiesArray = Array.isArray(model.capabilities) ? model.capabilities : [];
            const capabilitiesJson = JSON.stringify(capabilitiesArray);
            const form = {
                name: model.role.name,
                userIds: model.userIds,
                capabilities: capabilitiesArray
            }
            await super.addModel(this.api, form, false);
        } catch (error) {
            console.error("Loading expenses failed", error);
            throw error;
        }
    }

    async editModel(id, model) {
        try {
            const capabilitiesArray = Array.isArray(model.capabilities) ? model.capabilities : [];
            const capabilitiesJson = JSON.stringify(capabilitiesArray);

            const form = {
                name: model.role.name,
                userIds: model.userIds,
                capabilities: capabilitiesArray
            }

            await super.editModel(this.api, id, form, false);
        } catch (error) {
            console.error("Loading expenses failed", error);
            throw error;
        }
    }

    deleteModel(id) {
        super.deleteModel(this.api, id);
    }
}

const GlobalConfigStoreContext = createContext(new GlobalConfigStore());
export const useGlobalConfigStore = () => useContext(GlobalConfigStoreContext);

export default GlobalConfigStoreContext;
