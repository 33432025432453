import {makeAutoObservable, runInAction} from 'mobx';
import authApi from '../api/authApi';
import axios from 'axios';
import ConfigStore from "./configStore";

class AuthStore {
    token = localStorage.getItem('token') || null;
    refreshToken = localStorage.getItem('refresh_token') || null;
    tokenExpiresAt = localStorage.getItem('token_expires_at') || null;
    user = JSON.parse(localStorage.getItem('user')) || null;
    permissions = [];
    departmentId = '';
    selectedDepartmentId = '';
    selectedDepartmentName = ''
    error = null;
    isLoading = false;

    constructor() {
        makeAutoObservable(this);
        if (this.token) {
            this.fetchUser();
        }
        this.setupInterceptors();
    }

    login = async (email, password) => {
        const credentials = {
            email: email,
            password: password
        }

        this.isLoading = true;
        this.error = null;

        try {
            const data = await authApi.login(credentials);
            const tokenData = data.data.tokenData;

            runInAction(() => {
                this.token = tokenData.accessToken;
                this.refreshToken = tokenData.refreshToken;
                this.tokenExpiresAt = Date.now() + tokenData.expiresIn * 1000;
                this.user = data.data.user;
                this.permissions = data.data.permissions;
                this.departmentId = data.data.user.departmentId;

                localStorage.removeItem('inDate');
                localStorage.setItem('token', this.token);
                localStorage.setItem('refresh_token', this.refreshToken);
                localStorage.setItem('token_expires_at', this.tokenExpiresAt);
                localStorage.setItem('user', JSON.stringify(this.toFormatUserObject(this.user)));
                localStorage.setItem('department_id', this.departmentId);

                if (data.data.permissions?.departmentManagement?.selectDepartment == false) {
                    this.selectedDepartmentId = data.data.user.departmentId;
                    this.selectedDepartmentName = data.data.user.departmentName;
                    localStorage.setItem('selected_department_id', this.departmentId);
                    localStorage.setItem('selected_department_name', this.selectedDepartmentName);
                }

                ConfigStore.fetchConfigData();

                this.isLoading = false;
                this.error = null;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error.response ? error.response.data.message : 'Network Error';
            });
            throw error;
            console.error("Login failed", error);
        }
    }

    forgotPassword = async (email) => {
        const credentials = {
            email: email,
        }

        this.isLoading = true;
        this.error = null;

        try {
            const data = await authApi.forgotPassword(credentials);
            runInAction(() => {
                this.isLoading = false;
                this.error = null;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error.response ? error.response.data : 'Network Error';
            });
            throw error;
            console.error("Login failed", error);
        }
    }

    sendOtp = async (email, otp) => {
        const credentials = {
            email: email,
            otp: otp,
        }

        this.isLoading = true;
        this.error = null;

        try {
            const data = await authApi.sendOtp(credentials);
            runInAction(() => {
                this.isLoading = false;
                this.error = null;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error.response ? error.response.data : 'Network Error';
            });
            throw error;
            console.error("Login failed", error);
        }
    }

    sendNewPass = async (email, otp, password) => {
        const credentials = {
            email: email,
            otp: otp,
            password: password
        }

        this.isLoading = true;
        this.error = null;

        try {
            const data = await authApi.sendNewPass(credentials);
            runInAction(() => {
                this.isLoading = false;
                this.error = null;
            });
        } catch (error) {
            runInAction(() => {
                this.isLoading = false;
                this.error = error.response ? error.response.data : 'Network Error';
            });
            throw error;
            console.error("Login failed", error);
        }
    }

    register = async (name, email, password, roleId) => {
        this.isLoading = true;
        this.error = null;
        try {
            // const response = await axios.post('/register', { name, email, password, role_id: roleId });
            // this.token = response.data.token;
            localStorage.setItem('token', this.token);
            // await this.fetchUser();
            runInAction(() => {
                this.isLoading = false;
                this.error = null;
            });
        } catch (error) {
            console.error("Registration failed", error);
            runInAction(() => {
                this.isLoading = false;
                this.error = error.response ? error.response.data.message : 'Network Error';
            });
        }
    }

    async fetchUser() {
        try {
            this.isLoading = true;

            const currentTime = Date.now();

            if (currentTime >= this.tokenExpiresAt) {
                await this.toRefreshToken();
            }

            const data = await authApi.getUser();

            const storageSelectedDepartmentId = localStorage.getItem('selected_department_id') ?? false
            const storageSelectedDepartmentName = localStorage.getItem('selected_department_name') ?? false

            runInAction(() => {
                this.user = data.data;
                this.permissions = data?.data?.userPermissions ?? '';
                this.departmentId = data.data.departmentId;
                //this.selectedDepartmentId =  data.data.departmentId;

                localStorage.setItem('user', JSON.stringify(this.toFormatUserObject(this.user)));
                localStorage.setItem('department_id', this.departmentId);

                if (storageSelectedDepartmentId) {
                    this.selectedDepartmentId = storageSelectedDepartmentId;
                }
                if (storageSelectedDepartmentName) {
                    this.selectedDepartmentName = storageSelectedDepartmentName;
                }

                ConfigStore.fetchConfigData();

                this.isLoading = false;
            });
        } catch (error) {
            console.error('Fetching user failed', error);
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.isLoading = false;
            });

            if (error?.code === 'ERR_CANCELED') {
                return
            } else {
                await this.logout();
            }
        }
    }

    logout = async () => {
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('token_expires_at');
            localStorage.removeItem('user');
            localStorage.removeItem('inDate');
            localStorage.removeItem('department_id');
            localStorage.removeItem('selected_department_id');
            localStorage.removeItem('selected_department_name');

            runInAction(() => {
                this.isLoading = true;
                this.token = null;
                this.refreshToken = null;
                this.tokenExpiresAt = null;
                this.user = null;
                this.permissions = null;
                //  axios.defaults.headers.common['Authorization'] = null;
            });

            window.location.href = '/login';
            window.location.reload();
        } catch (error) {
            console.error("Logout failed", error);
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
                this.isLoading = false;
                this.error = error.response ? error.response.data.message : 'Network Error';
            });
        }
    }

    async toRefreshToken() {
        try {
            const data = await authApi.refreshToken(this.refreshToken);
            const tokenData = data.data;

            runInAction(() => {
                this.token = tokenData.accessToken;
                this.refreshToken = tokenData.refreshToken;
                this.tokenExpiresAt = Date.now() + tokenData.expiresIn * 1000;

                localStorage.setItem('token', this.token);
                localStorage.setItem('refresh_token', this.refreshToken);
                localStorage.setItem('token_expires_at', this.tokenExpiresAt);
            });
        } catch (error) {
            runInAction(() => {
                this.errors = error?.response?.data?.message ?? error;
            });
            console.log(error)
            await this.logout();
        }
    }

    toSelectDepartment(id, name) {
        runInAction(() => {
            this.selectedDepartmentId = id;
            this.selectedDepartmentName = name;
            localStorage.setItem('selected_department_id', id);
            localStorage.setItem('selected_department_name', name);
        })
    }

    setupInterceptors() {
        axios.interceptors.response.use(
            response => response,
            async error => {
                const originalRequest = error.config;
                if (error.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    await this.toRefreshToken();
                    originalRequest.headers['Authorization'] = `Bearer ${this.token}`;
                    return axios(originalRequest);
                }
                return Promise.reject(error);
            }
        );
    }

    resetStore () {
        this.error = null;
    }

    toFormatUserObject(user) {
        delete user?.password;
        return user
    }

    get isAuthenticated() {
        return !!this.token;
    }

    get isAdmin() {
        return this.user && this.user.role_id === 1;
    }

    getDepartmentName() {
        return this.user?.departmentName ?? null;
    }

    getRoleId() {
        return this.user?.roles?.[0]?.id ?? null;
    }
}

export const authStore = new AuthStore();
