import axiosConfig, {sendRequest} from './axiosConfig';
import {camelizeKeys, decamelizeKeys} from 'humps';

const roleApi = {
    getCapabilities: async (roleId = null) => {
        let response = null;

        if (roleId) {
            response = await axiosConfig.get(`/capabilities?role_id=${roleId}`);
        } else {
            response = await axiosConfig.get(`/capabilities`);
        }

        return camelizeKeys(response.data);
    },

    getAll: async (params) => {
        const response = await axiosConfig.get(`/roles`);
        return camelizeKeys(response.data);
    },

    create: async (data) => {
        const response = await sendRequest('/roles', 'post', data, {});
        return camelizeKeys(response.data);
    },

    update: async (id, data) => {
        const response = await sendRequest(`/roles/${id}`, 'put', data, {});
        return camelizeKeys(response.data);
    },

    delete: async (id) => {
        const response = await axiosConfig.delete(`/roles/${id}`);
        return camelizeKeys(response.data);
    }

};

export default roleApi;
