import React from "react";
import {observer} from "mobx-react-lite";
import Modal from "../common/Modal";
import {Button, Spinner} from "react-bootstrap";

export const RoleDelete = observer(({show, onClose, onDelete, isSubmitting}) => {
    return (
        <Modal show={show} onClose={onClose} title={'Delete role request'}>
            <div className={'text-in-delete-modal'}>Confirm the deletion of role request. Please note that this request
                will not be considered by management in the event of deletion.
            </div>
            <div className={'d-flex justify-content-between'}>
                <Button className={'button-cancel'} onClick={onClose}>Cancel</Button>
                <Button className={'button-delete'} onClick={onDelete} disabled={isSubmitting}>
                    {isSubmitting ? <Spinner as="span" animation="border" size="sm"/> : "Delete"}
                </Button>
            </div>
        </Modal>
    )
})

export default RoleDelete;
