import iconJpg from '../assets/images/icons/icon-jpg.svg';
import iconPng from '../assets/images/icons/icon-png.svg';
import iconPdf from '../assets/images/icons/icon-pdf.svg';

export const formatDate = (date) => {
    if (!date) return '';
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};

export const getIconFileExtension = (fileName) => {
    const extension = fileName.slice(fileName.lastIndexOf('.'));

    switch (extension) {
        case '.pdf':
            return <img className={'icon-file-extension'} src={iconPdf}></img>
            break;
        case '.jpg':
            return <img className={'icon-file-extension'} src={iconJpg}></img>
            break;
        case '.jpeg':
            return <img className={'icon-file-extension'} src={iconJpg}></img>
            break;
        case '.png':
            return <img className={'icon-file-extension'} src={iconPng}></img>
            break;
        default:
            return '.something'
            break;
    }
};

export const getFileName = (path) => {
    const lastDotIndex = path.lastIndexOf('.');

    if (lastDotIndex !== -1) {
        return path.substring(0, lastDotIndex);
    } else {
        return path;
    }
}

export const getFileNameFromFullPath = (path) => {
    if (path) {
        let fileName = path;

        const lastSlashIndex = path.lastIndexOf('/');
        const lastDotIndex = path.lastIndexOf('.');

        if (lastSlashIndex !== -1) {
            fileName = path.substring(lastSlashIndex + 1); // Обрезаем до последнего '/'
        }

        if (lastDotIndex !== -1) {
            fileName = fileName.substring(0, lastDotIndex); // Обрезаем от последней точки до конца
        }

        return fileName;
    }

    return '';
}

export const getFileExtension = (path) => {
    const dotIndex = path.lastIndexOf('.');

    if (dotIndex !== -1) {
        return path.slice(dotIndex + 1);
    } else {
        return 'Extension'
    }
}

export const formatDateToDisplay = (date) => {
    if (date) {
        const [year, month, day] = date.split('-');

        return `${day}/${month}/${year}`;
    }
    return '';
};

export const formatDateToDisplayFromDB = (isoDate) => {
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    if (!day || !month || !year) {
        return date;
    }

    return `${day}/${month}/${year}`;
}

export const formatDateToInput = (date) => {
    if (!date) return '';

    const [day, month, year] = date.split('/');

    if (!day || !month || !year) {
        return date;
    }

    return `${year}-${month}-${day}`;
};

export const formatDateToInputFromDB = (isoDate) => {
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    if (!day || !month || !year) {
        return date;
    }

    return `${year}-${month}-${day}`;
}

export const getImageUrl = (path) => {
    if (!path) return '';
    // return process.env.REACT_APP_API_BASE_URL + path;
    return 'https://tmstage.indacotms.com/backend/public/api' + path
}

export const truncateText = (text, maxLen = 40) => {
    if (!text) return '';

    if (text.length <= maxLen) {
        return text;
    }

    const truncatedText = text.slice(0, maxLen) + '...';

    return truncatedText ?? text;
};

export const convertTimeToHIS = (time) => {
    const [hours, minutes] = time.split(':');
    const seconds = '00';
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds}`;
}

export const convertTimeToHI = (dateTime) => {
    const date = new Date(dateTime);

    if (isNaN(date.getTime())) {
        return '';
    }

    const formattedTime = `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
    return formattedTime;
}

export const convertDateUTCToLocal = (utcDateTime) => {
    const date = new Date(utcDateTime + 'Z');

    if (isNaN(date.getTime())) {
        return '';
    }

    const localDate = date.toLocaleDateString();

    return localDate
}

export const convertTimeUTCToLocal = (utcDateTime) => {
    const date = new Date(utcDateTime + 'Z');

    if (isNaN(date.getTime())) {
        return '';
    }

    const localTime = date.toLocaleTimeString();

    return localTime
}

export const convertDateTimeUTCToLocal = (utcDateTime) => {
    const date = new Date(utcDateTime + 'Z');
    const localDate = date.toLocaleDateString();
    const localTime = date.toLocaleTimeString();

    return `${localDate} ${localTime}`;
}

export function convertDateTimeWithTimeZone(recordDate, recordTime) {
    const [hours, minutes, seconds] = recordTime.split(':');
    const date = new Date(recordDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10), 0);

    const timeZoneOffset = -date.getTimezoneOffset();
    const offsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
    const offsetMinutes = Math.abs(timeZoneOffset) % 60;

    const timeZoneFormatted =
        (timeZoneOffset >= 0 ? '+' : '-') +
        String(offsetHours).padStart(2, '0') + ':' +
        String(offsetMinutes).padStart(2, '0');

    const formattedDateTime = `${recordDate}T${recordTime}`;

    return `${formattedDateTime}${timeZoneFormatted}`;
}

export function convertDateTimeWithoutTimeZone(recordDate, recordTime) {
    const [hours, minutes, seconds] = recordTime.split(':');
    const date = new Date(recordDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), parseInt(seconds, 10), 0);
    const formattedDateTime = `${recordDate} ${recordTime}`;

    return `${formattedDateTime}`;
}

export function createTodayDateWithTime(time) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(0);

    return date;
}

export function convertToTimeWithOffset(isoDate, onlyDate = false) {
    if (!onlyDate && isoDate === 'Running') {
        return isoDate
    }

    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}`;
}

export function convertToTimeWithSecond(isoDate, onlyDate = false) {
    if (!onlyDate && isoDate === 'Running') {
        return isoDate
    }

    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
        return '';
    }

    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${hours}:${minutes}:00`;
}


export function removeSecondsFromHMS(timeString) {
    const timePattern = /^\d{2}:\d{2}:\d{2}$/;

    if (timePattern.test(timeString)) {
        return timeString.slice(0, 5);
    } else {
        return ''
    }
}

export function timeAgo(dateString) {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        return '';
    }

    const now = new Date();
    const diffInSeconds = Math.floor((now - date) / 1000);
    const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
    };

    for (let key in intervals) {
        const interval = Math.floor(diffInSeconds / intervals[key]);
        if (interval > 1) {
            return `${interval} ${key}s ago`;
        } else if (interval === 1) {
            return `${interval} ${key} ago`;
        }
    }

    return 'just now';
}

export function daysBetween(startDate, endDate) {
    const start = new Date(`${startDate}T00:00:00Z`);
    const end = new Date(`${endDate}T00:00:00Z`);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return '';
    }

    const diffInMilliseconds = end - start;
    const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));

    return `${diffInDays} day${diffInDays !== 1 ? 's' : ''}`;
}

export function formatDateRange(startDate, endDate) {
    const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short'
    };

    const formatDate = (date) => {
        const dayNumber = date.getDate();
        const daySuffix = getDaySuffix(dayNumber);
        const formattedDate = date.toLocaleDateString('en-US', options);

        return formattedDate.replace(dayNumber, `${dayNumber}${daySuffix}`);
    };

    const getDaySuffix = (day) => {
        if (day % 10 === 1 && day !== 11) {
            return "st";
        } else if (day % 10 === 2 && day !== 12) {
            return "nd";
        } else if (day % 10 === 3 && day !== 13) {
            return "rd";
        } else {
            return "th";
        }
    };

    const start = new Date(startDate);
    const end = new Date(endDate);

    if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        return '';
    }

    return `${formatDate(start)} - ${formatDate(end)}`;
}

export function addSecondsIfMissing(time) {
    if (time.split(':').length === 2) {
        return `${time}:00`;
    }
    return time;
}

export function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + (seconds || 0);
}
