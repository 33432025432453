import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTimerStore} from '../../stores/timerStore';
import iconStop from "../../assets/images/icons/icon-stop.svg";
import iconPlay from "../../assets/images/icons/icon-play.svg";
import TimeTrackerAdd from './TimeTrackerAdd';
import FlashMessage from "../common/FlashMessage";
import {useLocation} from "react-router-dom";

const TimeTracker = observer(() => {
    const store = useTimerStore();
    const [showAddModal, setShowAddModal] = useState(false);
    const [message, setMessage] = useState(null);
    const [variant, setVariant] = useState('success');
    const [recordDateTime, setRecordDateTime] = useState(null);
    const location = useLocation();
    const timeListStore = useTimerStore();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const addScanTracker = async (model = null, scanType = 'in') => {
        setIsButtonDisabled(true);
        setTimeout(() => setIsButtonDisabled(false), 3000);

        try {
            await store.start(model, scanType);
            handleCloseModal();

            if (scanType === 'out' && (location.pathname === '/time-tracking' || location.pathname === '/')) {
                timeListStore.getTasksTrackingListToday();
            }

        } catch (error) {
            const errorData = error?.response?.data;
            setMessage(`Error!: ${error?.response?.data?.message}`);
            setVariant('danger');

            if (errorData?.data?.show_custom_time_popup) {
                if (errorData?.data?.record_date_time) {
                    setRecordDateTime(errorData?.data?.record_date_time);
                }
                setShowAddModal(true)
            } else {
                setMessage(`Tracker error, please try again later: ${error?.response?.data?.message}`);
                setVariant('danger');
                return;
            }
        }
    }

    const handleCloseModal = () => {
        setShowAddModal(false);
    };

    const resetErrorMessage = () => {
        setMessage(null)
        store.resetErrorsStore(false);
    }

    return (
        <>
            <div className={'tracker-block'}>
                <div className={store.intervalId ? 'time-block' : 'time-block tracker-disable'}>
                    {store.formattedTime}
                </div>
                {
                    store.intervalId ? (
                        <div className="stop-block">
                            <div className={`stop-icon ${isButtonDisabled ? 'disabled-button' : ''}`}>
                                <img
                                    onClick={() => !isButtonDisabled && addScanTracker(null, 'out')}
                                    src={iconStop}
                                    alt="stop"
                                    className="stop-icon"
                                    style={{opacity: isButtonDisabled ? 0.2 : 1}}
                                />
                            </div>
                            <div className="label-end">End time</div>
                        </div>
                    ) : (
                        <div className="start-block">
                            <div className={`start-icon ${isButtonDisabled ? 'disabled-button' : ''}`}>
                                <img
                                    onClick={() => !isButtonDisabled && addScanTracker('in')}
                                    src={iconPlay}
                                    alt="start"
                                    className="start-icon"
                                    style={{opacity: isButtonDisabled ? 0.2 : 1}}
                                />
                            </div>
                            <div className="label-start">Start time</div>
                        </div>
                    )
                }
            </div>
            <TimeTrackerAdd show={showAddModal} onClose={handleCloseModal} onAdd={addScanTracker}
                            recordDateTime={recordDateTime}></TimeTrackerAdd>
            <FlashMessage message={message} variant={variant} onClose={() => setMessage(null)}/>
            {!message &&
                <FlashMessage message={store.errors} variant={'danger'} onClose={() => resetErrorMessage()}/>
            }
        </>
    );
});

export default TimeTracker;