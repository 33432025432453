import React, {useEffect} from "react";
import RoleList from "../components/globalConfig/RoleList";


const GlobalConfigPage = () => {
    useEffect(() => {
        document.title = 'Global Config';
    }, []);

    return (
        <div className={'wrap-page'}>
            <RoleList/>
        </div>
    );
};

export default GlobalConfigPage;
