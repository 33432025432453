import {createContext, useContext} from "react";
import configStore from "./configStore";

const ConfigStoreContext = createContext(configStore);

export const useConfigStore = () => useContext(ConfigStoreContext);

export const ConfigStoreProvider = ({children}) => (
    <ConfigStoreContext.Provider value={configStore}>
        {children}
    </ConfigStoreContext.Provider>
);