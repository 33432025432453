import React from "react";
import {observer} from "mobx-react-lite";
import Modal from "../common/Modal";
import {Button, Spinner} from "react-bootstrap";

export const BreakManagementDelete = observer(({model, show, onClose, onDelete, isSubmitting}) => {
    return (
        <Modal show={show} onClose={onClose} title={`Delete ${model?.firstName} ${model?.lastName} tracked time`}>
            <div className={'text-in-delete-modal'}>
                Confirm the deletion of {model?.firstName} {model?.lastName} tracked time. Please note that deleted
                tracked time cannot be restored.
            </div>
            <div className={'d-flex justify-content-between mt-2'}>
                <Button className={'button-cancel'} onClick={onClose}>Cancel</Button>
                <Button className={'button-delete'} onClick={onDelete} disabled={isSubmitting}>
                    {isSubmitting ? <Spinner as="span" animation="border" size="sm"/> : "Delete"}
                </Button>
            </div>
        </Modal>
    )
})

export default BreakManagementDelete;
