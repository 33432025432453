import React, {useEffect} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';

const FlashMessage = ({message, variant, onClose}) => {
    useEffect(() => {
        if (message) {
            const existingToastContainers = document.querySelectorAll('.toast-container');
            existingToastContainers.forEach(container => {
                container.style.display = 'none';
            });

            const newToastContainer = document.querySelector('.toast-container');
            if (newToastContainer) {
                newToastContainer.style.display = 'block';
            }

            const timer = setTimeout(() => {
                onClose();
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [message, onClose]);

    if (!message) return null;

    const messageText = typeof message === 'object' ? 'Server Error!' : message;

    return (
        <ToastContainer className="toast-container p-3">
            <Toast onClose={onClose} bg={variant} show={true}>
                <Toast.Body>{messageText}</Toast.Body>
            </Toast>
        </ToastContainer>
    );
};

export default FlashMessage;