import axios from 'axios';
import {authStore} from '../stores/authStore';

const axiosConfig = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'https://tmstage.indacotms.com/backend/public/api',
    headers: {
        'Content-Type': 'application/json',
    },
});

const appendDepartmentId = (data, departmentId) => {
    if (!data) return;

    if (data instanceof FormData) {
        if (!data.has('department_id')) {
            data.append('department_id', departmentId);
        }
    } else {
        if (!data.department_id) {
            data.department_id = departmentId;
        }
    }
};

let activeRequests = {};

axiosConfig.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const selectedDepartmentId = authStore.selectedDepartmentId ?? '';

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (selectedDepartmentId) {
        appendDepartmentId(config.data, selectedDepartmentId);
    }

    if (activeRequests[config.url]) {
        activeRequests[config.url].abort();
    }

    const controller = new AbortController();
    config.signal = controller.signal;
    activeRequests[config.url] = controller;

    return config;
});

axiosConfig.interceptors.response.use(
    (response) => {
        delete activeRequests[response.config.url];
        return response;
    },
    (error) => {
        if (error.config) {
            delete activeRequests[error.config.url];
        }

        if (error?.response?.data?.message) {
            if (error?.response?.data?.message.includes("You do not have permission")) {
                authStore.fetchUser();
            }
        }

        if (error?.response?.data?.error === "Unauthenticated") {
            authStore.logout();
        }

        return Promise.reject(error);
    }
);

export const sendRequest = async (url, method = 'post', data, headers = {}, contentType = 'application/json') => {
    try {
        const selectedDepartmentId = authStore.selectedDepartmentId ?? '';
        if (selectedDepartmentId) {
            appendDepartmentId(data, selectedDepartmentId);
        }

        const response = await axiosConfig({
            url,
            method,
            data,
            headers: {
                'Content-Type': contentType,
                ...headers,
            },
        });
        return response.data;
    } catch (error) {
        if (axios.isCancel(error)) {
            console.log('Request cancelled:', error.message);
        } else {
            console.error('error:', error);
        }
        throw error;
    }
};

export default axiosConfig;