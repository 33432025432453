import React from 'react';
import {Nav} from 'react-bootstrap';
import '../../assets/styles/CustomBootstrap.css'
import logo from '../../assets/images/logos/logo.png';
import {observer} from 'mobx-react-lite';
import SidebarListPages from "../common/SidebarListPages";
import {useNavigate} from "react-router-dom";

const Sidebar = observer(() => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        navigate('/'); // Переход в корень сайта
    };

    return (
        <div className="custom-sidebar">
            <div>
                <div className='wrap-sidebar-logo'>
                    <img onClick={handleLogoClick} className={'sidebar-logo cursor-pointer'} src={logo}/>
                </div>
            </div>
            <Nav className="flex-column">
                <SidebarListPages></SidebarListPages>
            </Nav>
        </div>
    );
});

export default Sidebar;
